import { HttpError } from 'ra-core'
import * as api from '../../../shared/api'
import { extractAllErrors } from '../../../shared/errorExtractors'

export type DatabasesArray = Array<FreeDatabase | StandardDatabase>

interface Response {
  data: DatabasesArray
}

interface ServerResponse {
  data: Array<ServerResponseDatabase>
}

interface ServerResponseSingular {
  data: ServerResponseDatabase
}

interface ServerResponseDatabase {
  username: string
  url: string
  tier: 'FREE' | 'STANDARD'
  state: string
  port: number
  password: string
  id: string
  host: string
  database: string
  // eslint-disable-next-line camelcase
  app_name: string
  // eslint-disable-next-line camelcase
  limited_at: Date
  size: number
  region: string
  cloud: string
  // eslint-disable-next-line camelcase
  high_availability: 'enabled' | 'disabled'
  // eslint-disable-next-line camelcase
  read_replicas: number
}

export interface Database {
  username: string
  url: string
  state: string
  port: number
  password: string
  id: string
  host: string
  database: string
  appName: string
}

export interface StandardDatabase extends Database {
  tier: 'STANDARD'
  size: number
  region: string
  cloud: string
  highAvailability: 'enabled' | 'disabled'
  readReplicas: number
}
export interface FreeDatabase extends Database {
  tier: 'FREE'
  limitedAt: Date
}

interface ReadReplicaResponse {
  id: string
  host: string
  port: number
  url: string
  username: string
  password: string
  // eslint-disable-next-line camelcase
  app_name: string
  region: string
  cloud: string
  database: string
  size: number
  tier: 'STANDARD'
  state: string
  primary: string
  // eslint-disable-next-line camelcase
  high_availability: 'enabled' | 'disabled'
}

export interface ReadReplica {
  id: string
  host: string
  port: number
  url: string
  username: string
  password: string
  appName: string
  region: string
  cloud: string
  database: string
  tier: 'STANDARD'
  size: number
  state: string
  primary: string
  highAvailability: 'enabled' | 'disabled'
}

interface DatabaseBackup {
  id: string
  type: string
  status: string
}

export interface Backup extends DatabaseBackup {
  startTime: Date
}

interface BackupResponse extends DatabaseBackup {
  // eslint-disable-next-line camelcase
  start_time: string
}

export const get = (id: string): Promise<Response> => {
  return api.get<ServerResponse>(`/frontend/api/apps/${id}/databases`).then(
    (response): Response => {
      const databases = response.data.data
      const data = databases.map((database: ServerResponseDatabase):
        | FreeDatabase
        | StandardDatabase => {
        if (database.tier === 'FREE') {
          const db: FreeDatabase = {
            username: database.username,
            url: database.url,
            tier: database.tier,
            state: database.state,
            port: database.port,
            password: database.password,
            id: database.id,
            host: database.host,
            database: database.database,
            appName: database.app_name,
            limitedAt: database.limited_at,
          }
          return db
        }

        const db: StandardDatabase = {
          username: database.username,
          url: database.url,
          tier: database.tier,
          state: database.state,
          size: database.size,
          region: database.region,
          port: database.port,
          password: database.password,
          id: database.id,
          host: database.host,
          database: database.database,
          cloud: database.cloud,
          appName: database.app_name,
          highAvailability: database.high_availability,
          readReplicas: database.read_replicas,
        }
        return db
      })

      return {
        data,
      }
    }
  )
}

export const createFree = (appId: string): Promise<{ data: FreeDatabase }> => {
  return api
    .post<ServerResponseSingular>(
      `/frontend/api/apps/${appId}/free_databases`,
      {}
    )
    .then((response): { data: FreeDatabase } => {
      const r = response.data.data
      return {
        data: {
          id: r.id,
          username: r.username,
          url: r.url,
          tier: 'FREE',
          state: r.state,
          port: r.port,
          password: r.password,
          host: r.host,
          database: r.database,
          // eslint-disable-next-line camelcase
          appName: r.app_name,
          // eslint-disable-next-line camelcase
          limitedAt: r.limited_at,
        },
      }
    })
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          extractAllErrors(error.body.errors),
          error.status,
          error.body
        )
      )
    })
}

export const createStandard = (
  appId: string,
  size?: number
): Promise<{ data: StandardDatabase }> => {
  return api
    .post<ServerResponseSingular>(`/frontend/api/apps/${appId}/databases`, {
      size,
    })
    .then((response): { data: StandardDatabase } => {
      const r = response.data.data
      return {
        data: {
          id: r.id,
          username: r.username,
          url: r.url,
          tier: 'STANDARD',
          state: r.state,
          port: r.port,
          password: r.password,
          host: r.host,
          database: r.database,
          // eslint-disable-next-line camelcase
          appName: r.app_name,
          size: r.size,
          region: r.region,
          cloud: r.cloud,
          highAvailability: r.high_availability,
          readReplicas: r.read_replicas,
        },
      }
    })
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          extractAllErrors(error.body.errors),
          error.status,
          error.body
        )
      )
    })
}

export const del = (appId: string, id: string): Promise<{ data: null }> => {
  return api
    .del<ServerResponseSingular>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(id)}`
    )
    .then(() => ({
      data: null,
    }))
}

export const update = (
  appId: string,
  id: string,
  size: number,
  highAvailability: 'enabled' | 'disabled'
): Promise<{ data: StandardDatabase }> => {
  return api
    .put<ServerResponseSingular>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(id)}`,
      { size, high_availability: highAvailability }
    )
    .then((response): { data: StandardDatabase } => {
      const r = response.data.data
      return {
        data: {
          id: r.id,
          username: r.username,
          url: r.url,
          tier: 'STANDARD',
          state: r.state,
          port: r.port,
          password: r.password,
          host: r.host,
          database: r.database,
          // eslint-disable-next-line camelcase
          appName: r.app_name,
          size: r.size,
          region: r.region,
          cloud: r.cloud,
          highAvailability: r.high_availability,
          readReplicas: r.read_replicas,
        },
      }
    })
}

export const getReadReplicas = (
  appId: string,
  databaseId: string
): Promise<{ data: ReadReplica[] }> => {
  return api
    .get<{ data: ReadReplicaResponse[] }>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(
        databaseId
      )}/read_replicas`
    )
    .then((response): { data: ReadReplica[] } => {
      const readReplicas = response.data.data
      return {
        data: readReplicas.map(
          // eslint-disable-next-line camelcase
          ({ app_name, high_availability, ...readReplica }) => ({
            ...readReplica,
            appName: app_name,
            highAvailability: high_availability,
          })
        ),
      }
    })
}

export const createReadReplica = (
  appId: string,
  databaseId: string,
  size?: number
): Promise<{ data: ReadReplica }> => {
  return api
    .post<ReadReplicaResponse>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(
        databaseId
      )}/read_replicas`,
      { size }
    )
    .then((response): { data: ReadReplica } => {
      // eslint-disable-next-line camelcase
      const { app_name, high_availability, ...readReplica } = response.data
      return {
        data: {
          ...readReplica,
          appName: app_name,
          highAvailability: high_availability,
        },
      }
    })
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          extractAllErrors(error.body.errors),
          error.status,
          error.body
        )
      )
    })
}

export const updateReadReplica = (
  appId: string,
  readReplicaId: string,
  size: number,
  highAvailability: 'enabled' | 'disabled'
): Promise<{ data: ReadReplica }> => {
  return api
    .put<ReadReplicaResponse>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(
        readReplicaId
      )}`,
      { size, high_availability: highAvailability }
    )
    .then((response): { data: ReadReplica } => {
      const r = response.data
      return {
        data: {
          id: r.id,
          username: r.username,
          url: r.url,
          tier: 'STANDARD',
          state: r.state,
          port: r.port,
          password: r.password,
          host: r.host,
          database: r.database,
          // eslint-disable-next-line camelcase
          appName: r.app_name,
          size: r.size,
          region: r.region,
          cloud: r.cloud,
          highAvailability: r.high_availability,
          primary: r.primary,
        },
      }
    })
}

export const deleteReadReplica = (
  appId: string,
  readReplicaId: string
): Promise<{ data: null }> => {
  return api
    .del<ReadReplicaResponse>(
      `/frontend/api/apps/${appId}/databases/${encodeURIComponent(
        readReplicaId
      )}`
    )
    .then(() => ({
      data: null,
    }))
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          extractAllErrors(error.body.errors),
          error.status,
          error.body
        )
      )
    })
}

export const getBackups = (
  appId: string,
  id: string
): Promise<{ data: Backup[] }> => {
  return api
    .get<{ data: BackupResponse[] }>(
      `/frontend/api/apps/${appId}/databases/${id}/backups`
    )
    .then((response): { data: Backup[] } => {
      const backups = response.data.data
      return {
        data: backups.map((backup) => {
          const { start_time, ...rest } = backup // eslint-disable-line camelcase
          return {
            ...rest,
            startTime: new Date(start_time),
          }
        }),
      }
    })
}

export const restore = (
  appId: string,
  id: string,
  backupId: string
): Promise<{ data: StandardDatabase }> => {
  return api
    .post<ServerResponseSingular>(
      `/frontend/api/apps/${appId}/databases/${id}/backups/${backupId}/restore`,
      {}
    )
    .then((response): { data: StandardDatabase } => {
      const {
        // eslint-disable-next-line camelcase
        app_name,
        // eslint-disable-next-line camelcase
        high_availability,
        // eslint-disable-next-line camelcase
        read_replicas,
        ...rest
      } = response.data.data
      return {
        data: {
          ...rest,
          appName: app_name,
          tier: 'STANDARD',
          highAvailability: high_availability,
          readReplicas: read_replicas,
        },
      }
    })
}
