import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { withRouter } from 'react-router'
import ScaleDialog from './ScaleDialog'
import DeleteDialog from './DeleteDialog'
import Tabs from './Tabs'
import { FreeDatabase, StandardDatabase } from '../actions'
import DialogButton, {
  CloseFunction,
} from '../../../../shared/components/DialogButton'
import ButtonPanel from '../../../../shared/components/ButtonPanel'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  header: {
    height: theme.spacing(3),
    paddingBottom: theme.spacing(),
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
}))

interface Props {
  appId: string
  database: StandardDatabase | FreeDatabase
  dividingBorder?: boolean
}

interface EnhancedProps {
  match: {
    params: {
      subtab: string
    }
  }
}

const Database: FunctionComponent<Props & EnhancedProps> = ({
  appId,
  database,
  match,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {database.tier}
        {match.params.subtab !== 'read-replicas' && (
          <ButtonPanel>
            <DialogButton label="Scale">
              {(close: CloseFunction) => {
                return (
                  <ScaleDialog
                    appId={appId}
                    database={database}
                    onSuccess={() => close()}
                    onCancel={() => close()}
                  />
                )
              }}
            </DialogButton>
            <DialogButton label="Destroy">
              {(close: CloseFunction) => {
                return (
                  <DeleteDialog
                    appId={appId}
                    databaseId={database.id}
                    onCancel={() => close()}
                    onSuccess={() => close()}
                  />
                )
              }}
            </DialogButton>
          </ButtonPanel>
        )}
      </div>
      <Tabs appId={appId} database={database} />
    </div>
  )
}

const EnhancedDatabase = compose<Props & EnhancedProps, Props>(withRouter)(
  Database
)

export default EnhancedDatabase
