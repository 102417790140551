import * as databases from './actions'
import {
  CreateDatabaseParams,
  UpdateDatabaseParams,
  DeleteDatabaseParams,
  GetBackupsParams,
  RestoreDatabaseParams,
  GetReadReplicasParams,
  CreateReadReplicaParams,
  DeleteReadReplicaParams,
  UpdateReadReplicaParams,
} from './dataProviderParams'
import {
  CreateParams,
  UpdateParams,
} from '../../../shared/dataProvider/interfaces'
import { isGetListByID } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isCreateDatabase = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateDatabaseParams> =>
  method === 'CREATE' && resource === 'databases'

const isUpdateDatabase = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateDatabaseParams> =>
  method === 'UPDATE' && resource === 'databases'

const isDeleteDatabase = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteDatabaseParams =>
  method === 'DELETE' && resource === 'databases'

const isGetReadReplicas = (
  method: Method,
  resource: Resource,
  params: Params
): params is GetReadReplicasParams =>
  method === 'GET_READ_REPLICAS' && resource === 'databases'

const isCreateReadReplica = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateReadReplicaParams> =>
  method === 'CREATE_READ_REPLICA' && resource === 'databases'

const isUpdateReadReplica = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateReadReplicaParams> =>
  method === 'UPDATE_READ_REPLICA' && resource === 'databases'

const isDeleteReadReplica = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteReadReplicaParams =>
  method === 'DELETE_READ_REPLICA' && resource === 'databases'

const isGetBackups = (
  method: Method,
  resource: Resource,
  params: Params
): params is GetBackupsParams =>
  method === 'GET_BACKUPS' && resource === 'databases'

const isRestoreDatabase = (
  method: Method,
  resource: Resource,
  params: Params
): params is RestoreDatabaseParams =>
  method === 'RESTORE' && resource === 'databases'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteDatabase(method, resource, params)) {
    return databases.del(params.appId, params.id)
  }
  if (isCreateDatabase(method, resource, params)) {
    const { size, tier, appId } = params.data
    if (tier === 'STANDARD') return databases.createStandard(appId, size)
    return databases.createFree(appId)
  }
  if (isUpdateDatabase(method, resource, params)) {
    const { appId, size, highAvailability } = params.data
    return databases.update(appId, params.id, size, highAvailability)
  }
  if (isGetReadReplicas(method, resource, params)) {
    return databases.getReadReplicas(params.appId, params.databaseId)
  }
  if (isCreateReadReplica(method, resource, params)) {
    const { appId, databaseId, size } = params.data
    return databases.createReadReplica(appId, databaseId, size)
  }
  if (isUpdateReadReplica(method, resource, params)) {
    const { appId, readReplicaId, size, highAvailability } = params.data
    return databases.updateReadReplica(
      appId,
      readReplicaId,
      size,
      highAvailability
    )
  }
  if (isDeleteReadReplica(method, resource, params)) {
    return databases.deleteReadReplica(params.appId, params.readReplicaId)
  }
  if (isGetBackups(method, resource, params)) {
    return databases.getBackups(params.appId, params.id)
  }
  if (isRestoreDatabase(method, resource, params)) {
    return databases.restore(params.appId, params.id, params.backupId)
  }
  if (isGetListByID(method, resource, params)) {
    return databases.get(params.id)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
